import Layout from "../components/layout";
import React from "react"

function Contact(){
    return(
        <Layout>
            Coming soon!
        </Layout>
    )
}

export default Contact